@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@font-face {
  font-family: 'SF Pro Display';
  src: url('fonts/SFProDisplay-BlackItalic.woff2') format('woff2'), url('fonts/SFProDisplay-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('fonts/SFProDisplay-Bold.woff2') format('woff2'), url('fonts/SFProDisplay-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('fonts/SFProDisplay-HeavyItalic.woff2') format('woff2'), url('fonts/SFProDisplay-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('fonts/SFProDisplay-LightItalic.woff2') format('woff2'), url('fonts/SFProDisplay-LightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('fonts/SFProDisplay-Regular.woff2') format('woff2'), url('fonts/SFProDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('fonts/SFProDisplay-ThinItalic.woff2') format('woff2'), url('fonts/SFProDisplay-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('fonts/SFProDisplay-Medium.woff2') format('woff2'), url('fonts/SFProDisplay-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('fonts/SFProDisplay-SemiboldItalic.woff2') format('woff2'), url('fonts/SFProDisplay-SemiboldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('fonts/SFProDisplay-UltralightItalic.woff2') format('woff2'), url('fonts/SFProDisplay-UltralightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
body {
  font-family: 'SF Pro Display';
  padding: 0;
  margin: 0;
  color: #858a8c;
  font-size: 24px;
  line-height: 1.2;
  overflow-x: hidden;
  background-image: url('../public/images/banner-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
img {
  display: block;
  max-width: 100%;
  height: auto;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
h1,
h2 {
  font-family: 'Bebas Neue';
  color: #000;
}
.banner-section {
  /* background-image: url("../public/images/banner-bg.webp"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 25px;
  position: relative;
}
.banner-section .banner-container {
  padding: 0 40px;
}
.banner-section .head-icon {
  display: inline-block;
}
.banner-section .banner-cartoon-img {
  position: absolute;
  top: 0;
  left: 0;
}
.banner-section .banner-content {
  max-width: 760px;
  margin: 0 auto;
  position: relative;
  z-index: 9;
}
.banner-section .banner-logo {
  text-align: right;
  margin-bottom: 40px;
}
.banner-section .banner-logo img {
  margin-left: auto;
}
.content-detail .banner-title {
  position: relative;
  max-width: 390px;
  text-align: center;
  margin: 0 auto 12px;
  z-index: 1;
}
.content-detail .banner-title h1 {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}
.content-detail .banner-title img {
  max-width: 100%;
}
.content-discriptions {
  text-align: center;
  position: relative;
  top: -24px;
}
.content-discriptions p {
  background: #e9e8e6;
  font-size: 24px;
  line-height: 1.2;
  padding: 10px 5px;
  margin: 0 0 10px;
  box-shadow: 0 0px 9px 0 #00000021;
  color: #858a8c;
}
.content-discriptions p:first-child {
  transform: rotate(-3deg);
}
.content-discriptions p:last-child {
  transform: rotate(-4deg);
}
.banner-enter-text .text-hand-title {
  position: absolute;
  right: 40px;
  text-align: center;
  max-width: 315px;
  top: 118px;
}
.banner-enter-text {
  position: absolute;
  right: 0;
  top: 165px;
  z-index: 1;
}
.morph-section {
  margin-top: 415px;
  position: relative;
}
.morph-section .morph-cards {
  display: flex;
  justify-content: flex-end;
  right: -30px;
  position: relative;
  z-index: 9;
}
.banner-bg-cartoon {
  position: absolute;
  bottom: 0;
  left: -40px;
  opacity: 0;
}
.card-content .card-discriptions {
  max-width: 500px;
  margin: 0;
  padding: 20px 20px;
  border-radius: 10px;
  background: #e9ede6;
  font-size: 20px;
  line-height: 1.2;
}
.card-content .card-discriptions p {
  margin: 0;
  text-align: center;
}

@media (max-width: 767px) {
  .morph-card-holder .card-image {
    position: relative;
    padding-left: 10vw;
    padding-right: 10vw;
    z-index: 9;
  }
  .card-content {
    position: relative;
    padding-left: 10vw;
    padding-right: 10vw;
    top: -20px;
  }
}

.morph-card-holder .card-image {
  position: relative;
  z-index: 9;
}
.card-content {
  position: relative;
  top: -20px;
}

.morph-section .morph-cards .morph-card-holder:first-child .card-content .card-discriptions:first-child {
  transform: rotate(4deg);
}

.hidden1:nth-child(2) {
  transition-delay: 200ms;
}

.morph-section .morph-cards .morph-card-holder:first-child .card-content .card-discriptions:nth-child(2) {
  transform: rotate(-5deg);
}

.morph-section .morph-cards .morph-card-holder:first-child .card-content .card-discriptions {
  max-width: 420px;
  margin: 0 auto;
}
.morph-section .morph-cards .morph-card-holder:first-child .card-content .card-discriptions:last-child {
  transform: rotate(4deg);
  position: relative;
  /* top: -10px; */
}
.morph-section .morph-cards .morph-card-holder:last-child .card-content .card-discriptions {
  background: #e9e8e6;
}
.morph-section .morph-circle {
  position: absolute;
  right: 314px;
  z-index: 99;
}
.morph-section .morph-cards .morph-card-holder:nth-child(2) {
  padding-top: 120px;
}
.morph-section .morph-title {
  position: absolute;
  left: 338px;
  right: 0;
  max-width: 330px;
  margin: 0 auto;
  top: -35px;
}
.morph-section .morph-circle {
  position: absolute;
  right: 287px;
  z-index: 99;
  top: -56px;
}
section.morph-collection {
  position: relative;
  /*
  background: #dcf9f7;
  background: rgb(245,201,255);
background: -moz-linear-gradient(0deg, rgba(245,201,255,1) 0%, rgba(255,245,201,1) 100%);
background: -webkit-linear-gradient(0deg, rgba(245,201,255,1) 0%, rgba(255,245,201,1) 100%);
background: linear-gradient(0deg, rgba(245,201,255,1) 0%, rgba(255,245,201,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f5c9ff",endColorstr="#fff5c9",GradientType=1);
*/
}
.morph-collection-detail {
  position: absolute;
  top: 50%;
  right: 80px;
  transform: translateY(-50%);
}
.morph-collection-detail .morph-collection-circle {
  margin-top: 40px;
}
.morph-collection-detail .morph-collection-circle img {
  max-width: 90%;
}
.morph-collection-detail .morph-collection-bgimg img {
  margin: 0 8% 0px;
}
.morph-collection-bgimg img {
  opacity: 0;
}
.dimond-section .dimond-video {
  text-align: center;
  max-width: 830px;
  position: relative;
  margin: 0 auto;
  padding-top: 55px;
}
.dimond-section .dimond-video iframe {
  width: 735px;
  height: 435px;
  margin: 0 auto;
}
.dimond-section .dimond-video .dimond-video-title {
  position: absolute;
  top: 0;
  left: 0;
}
.dimond-section {
  /* background-image: url('../public/images/slider-bg.webp'); */
  background-image: none;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* background: #f5c9ff;  */
}
.dimond-section .edouard-section {
  position: relative;
  top: -45px;
}
.edouard-section .edouard-title {
  background: #ffffff5e;
  text-align: center;
  max-width: 490px;
  margin: 0 auto;
  position: relative;
  padding: 6px 18px 0;
}
.edouard-section .edouard-title img {
  margin: 0 auto;
}
.edouard-section .edouard-slider {
  max-width: 499px;
}
.edouard-section .edouard-content .edouard-content-dis {
  background: #e1e1e1;
  padding: 20px;
  border-radius: 15px;
  max-width: 560px;
  /* margin-left: auto; */
  text-align: center;
  font-size: 24px;
  line-height: 1.2;
  transform: rotate(-3deg);
  margin-right: 15px;
}
.edouard-section .edouard-content .edouard-content-dis p:last-child {
  margin: 0;
}
.edouard-section .edouard-content-slider {
  margin-top: 75px;
}
.edouard-section .collab-section .collab-title {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: -10px;
}
.edouard-section .collab-section {
  text-align: center;
  position: relative;
  padding: 41px 0;
}
.collab-section .collab-image img {
  margin: 0 auto;
}
.toundery-section {
  text-align: center;
  background-image: url('../public/images/footer-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.toundery-section {
  text-align: center;
  padding: 40px 0;
}
.toundery-section .toundery-title .toundery-heading {
  position: relative;
  top: -13px;
  left: 11px;
}
.toundery-section .toundery-title .toundery-content {
  max-width: 860px;
  margin: 35px auto 0;
}
.toundery-section .toundery-title img {
  margin: 0 auto;
  max-width: 322px;
}
.toundery-section .toundery-title .toundery-heading img {
  max-width: 290px;
}
.toundery-section .toundery-list ul {
  display: flex;
}
.toundery-section .toundery-list {
  margin-top: 30px;
  margin-bottom: 50px;
}
.list-detail .list-content {
  max-width: 314px;
  margin: 0 10% 0;
}
.faq-section {
  position: relative;
}
.faq-section .faq-title img {
  margin: 0 auto;
}
.faq-section .faq-title {
  margin-bottom: 40px;
}
.faq-content .faq-content-detail h3 {
  text-transform: uppercase;
  color: #626262;
  font-weight: 600;
  font-size: 30px;
  font-family: 'Bebas Neue';
  letter-spacing: 1.3px;
}
.faq-content .faq-content-detail {
  margin-bottom: 40px;
}
.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-content ul {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.footer-content ul li {
  margin: 0 5px;
}
.faq-section .faq-content {
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  z-index: 9;
}
.faq-section .faq-cartoon-img-two {
  position: absolute;
  right: 0;
  bottom: -40px;
}
.faq-cartoon-img-one {
  position: absolute;
  left: 0;
  top: 0;
}
.collab-section .collab-title {
  position: absolute;
  left: 0;
  right: 0;
  top: -42px;
}
.collab-section .collab-title img {
  margin: 0 auto;
}
.collab-section {
  position: relative;
  padding: 10px 0px 40px;
}
.edouard-section .edouard-slider .slick-slider .slick-prev,
.edouard-section .edouard-slider .slick-slider .slick-next {
  background-image: url('../public/images/left-arrow.webp');
  width: 26px;
  height: 56px;
  background-repeat: no-repeat;
  background-size: 26px 56px;
}
.edouard-section .edouard-slider .slick-slider .slick-prev {
  left: -18px;
  z-index: 1;
}
.edouard-section .edouard-slider .slick-slider .slick-next {
  background-image: url('../public/images/right-arrow.webp');
  right: -5px;
}
.edouard-section .edouard-slider .slick-slider .slick-prev:before,
.edouard-section .edouard-slider .slick-slider .slick-next:before {
  display: none;
}
.edouard-section .edouard-content-slider .edouard-content {
  margin-top: 5px;
}
.faq-content-detail.accordion-item button.accordion-button {
  background-color: transparent;
  text-align: center;
  justify-content: center;
  color: #626262;
  font-size: 32px;
  align-content: center;
  position: relative;
  padding: 0 0;
  outline: 0;
  box-shadow: none;
}
.faq-content-detail.accordion-item button.accordion-button:after {
  position: absolute;
  right: 0;
  font-weight: 700;
  color: #626262;
  content: none;
}
.faq-content-detail.accordion-item button.accordion-button span {
  position: relative;
}
.faq-content-detail.accordion-item button.accordion-button span:after {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-left: auto;
  content: '';
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e');
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  position: absolute;
  right: -32px;
  top: 2px;
}
.faq-content-detail.accordion-item button[aria-expanded='true'] span:after {
  transform: rotate(-180deg);
  top: -1px;
}
.faq-content-detail.accordion-item {
  background-color: transparent;
}
@media (max-width: 786px) {
  .faq-content-detail.accordion-item button.accordion-button {
    font-size: 22px;
  }
}
@media (max-width: 1879px) {
  .faq-section .faq-content {
    max-width: 850px;
  }
  .banner-enter-text .text-hand-image img {
    max-width: 670px;
  }
  .banner-enter-text .text-hand-title img {
    max-width: 250px;
  }
  .morph-section .morph-title {
    left: 220px;
  }
}
@media (max-width: 1749px) {
  .faq-section .faq-cartoon-img-two img {
    max-width: 440px;
  }
  .faq-cartoon-img-one img {
    max-width: 450px;
  }
  .morph-section .morph-title {
    left: 0;
    max-width: 250px;
    top: 0;
  }
  .morph-section {
    margin-top: 375px;
  }
}
@media (max-width: 1599px) {
  .banner-section .banner-logo img {
    max-width: 410px;
    margin-right: auto;
  }
  .banner-section .banner-content {
    max-width: 600px;
  }
  .content-discriptions p,
  .edouard-section .edouard-content .edouard-content-dis,
  body {
    font-size: 20px;
  }
  .card-content .card-discriptions {
    font-size: 17px;
  }
  .edouard-section .edouard-content .edouard-content-dis {
    max-width: 510px;
  }
}
@media (max-width: 1399px) {
  .banner-section .banner-logo img {
    max-width: 310px;
  }
  .banner-section .banner-logo {
    margin-bottom: 20px;
  }
  .morph-section .morph-circle img {
    max-width: 160px;
  }
  .morph-section .morph-circle {
    right: 258px;
    top: 6px;
  }
  .banner-section .banner-cartoon-img img {
    max-width: 300px;
    margin-left: 30px;
  }
  .banner-enter-text .text-hand-image img {
    max-width: 470px;
  }
  .banner-enter-text .text-hand-title img {
    max-width: 200px;
  }
  .banner-enter-text .text-hand-title {
    right: 13px;
    max-width: 315px;
    top: 100px;
  }
  .morph-section {
    margin-top: 175px;
  }
  .morph-collection-detail .morph-collection-card img {
    max-width: 450px;
    margin-left: auto;
  }
  .morph-collection-detail .morph-collection-circle img {
    max-width: 57%;
    margin-left: auto;
  }
  .morph-collection-bgimg img {
    max-width: 750px;
  }
  .faq-section .faq-cartoon-img-two img {
    max-width: 340px;
  }
  .faq-cartoon-img-one img {
    max-width: 350px;
  }
  .faq-section .faq-content {
    max-width: 650px;
  }
}
@media (max-width: 1199px) {
  .morph-section .morph-circle {
    right: 195px;
  }
  .morph-section .morph-title {
    max-width: 215px;
  }
  .card-content .card-discriptions {
    font-size: 15px;
  }
  .edouard-section .edouard-slider .slick-slider .slick-slide img {
    width: 96%;
  }
  .toundery-section .toundery-title img {
    max-width: 300px;
  }
  .toundery-section .toundery-title .toundery-content {
    margin: 15px auto 0;
  }
  .faq-cartoon-img-one img {
    max-width: 290px;
  }
  .faq-section .faq-cartoon-img-two img {
    max-width: 240px;
  }
}
@media (max-width: 1023px) {
  .banner-enter-text .text-hand-title img {
    max-width: 173px;
  }
}
@media (max-width: 991px) {
  .dimond-section .edouard-section {
    position: relative;
    top: -15px;
  }
  .dimond-section .dimond-video iframe {
    width: 535px;
    height: 335px;
  }
  .content-discriptions p,
  .edouard-section .edouard-content .edouard-content-dis,
  body {
    font-size: 16px;
  }
  .collab-section .collab-title img {
    max-width: 220px;
  }
  .card-content .card-discriptions {
    padding: 15px;
  }
  .morph-collection-detail .morph-collection-card img {
    max-width: 350px;
  }
  .morph-collection-detail .morph-collection-circle {
    margin-top: 20px;
  }
  .morph-collection-detail .morph-collection-circle img {
    max-width: 46%;
  }
  .morph-collection-bgimg img {
    max-width: 515px;
  }
  .faq-cartoon-img-one img {
    max-width: 230px;
  }
  .faq-section .faq-cartoon-img-two img {
    max-width: 150px;
  }
  .toundery-section {
    padding: 30px 0;
  }
  .faq-section .faq-cartoon-img-two {
    bottom: -30px;
  }
  .dimond-section .dimond-video {
    max-width: 580px;
  }
  .dimond-section .dimond-video .dimond-video-title img {
    max-width: 200px;
  }
  .edouard-section .edouard-title {
    max-width: 360px;
  }
  .collab-section .collab-image img {
    max-width: 400px;
  }
  .banner-section .banner-content {
    max-width: 450px;
  }
  .banner-enter-text .text-hand-image img {
    max-width: 470px;
  }
  .banner-enter-text {
    top: 10px;
  }
  .banner-section .banner-container {
    padding: 0 20px;
  }
  .morph-section .morph-cards {
    right: -10px;
  }
}
@media (max-width: 767px) {
  .morph-section .morph-cards {
    right: 0;
    flex-wrap: wrap;
  }
  .faq-content-detail.accordion-item button.accordion-button {
    font-size: 18px;
  }
  .banner-section .banner-logo img {
    max-width: 230px;
  }
  .banner-enter-text .text-hand-image img {
    max-width: 270px;
  }
  .banner-enter-text .text-hand-title img {
    max-width: 115px;
  }
  .banner-enter-text .text-hand-title {
    right: 13px;
    max-width: 315px;
    top: 22px;
  }
  .content-detail .banner-title img {
    max-width: 80%;
    margin: 0 auto;
  }
  .content-detail .banner-title h1 {
    font-size: 26px;
  }
  .morph-section .morph-title {
    top: -107px;
  }
  .morph-section .morph-circle {
    right: 0;
  }
  .morph-section .morph-cards .morph-card-holder:nth-child(2) {
    padding-top: 0;
  }
  .morph-collection-detail .morph-collection-card img {
    max-width: 250px;
  }
  .morph-collection-bgimg img {
    max-width: 365px;
  }
  .morph-collection-detail .morph-collection-circle img {
    max-width: 40%;
  }
  .dimond-section .dimond-video iframe {
    width: 435px;
    height: 235px;
  }
  .edouard-section .edouard-content .edouard-content-dis {
    margin-bottom: 30px;
  }
  .edouard-section .edouard-slider {
    max-width: 470px;
    margin: 0 auto;
  }
  .toundery-section .toundery-title img {
    max-width: 200px;
  }
  .toundery-section .toundery-title .toundery-heading img {
    max-width: 200px;
  }
  .list-detail .list-content {
    /* margin: 0 10px; */
  }
  .faq-section .faq-title img {
    max-width: 110px;
  }
  .faq-section .faq-title {
    margin-bottom: 20px;
  }
  .faq-content .faq-content-detail h3 {
    font-size: 20px;
    letter-spacing: 1.2px;
  }
  .faq-cartoon-img-one img {
    max-width: 125px;
  }
  .faq-content .faq-content-detail {
    margin-bottom: 20px;
  }
  .faq-section .faq-cartoon-img-two img {
    max-width: 81px;
  }
  .faq-section .faq-cartoon-img-two {
    bottom: -20px;
  }
  .toundery-section {
    padding: 20px 0;
  }
  .toundery-section .toundery-list {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
@media (max-width: 574px) {
  .dimond-section .dimond-video iframe {
    width: 300px;
    height: 200px;
  }
  .collab-section .collab-image img {
    max-width: 250px;
  }
  .morph-collection-bgimg img {
    max-width: 300px;
  }
  .banner-section .banner-cartoon-img img {
    margin: 0;
    max-width: 100px;
  }
  .banner-section .banner-logo img {
    max-width: 190px;
  }
  .banner-section .head-icon img {
    max-width: 30px;
  }
  .morph-section {
    margin-top: 100px;
  }
  .morph-section .morph-circle img {
    max-width: 100px;
  }
  .morph-section .morph-title img {
    max-width: 150px;
  }
  .morph-section .morph-title {
    top: -80px;
  }
  .morph-collection-detail {
    right: 10px;
  }
  .morph-collection-detail .morph-collection-card img {
    max-width: 150px;
  }
  .dimond-section .dimond-video .dimond-video-title img {
    max-width: 160px;
  }
  .edouard-section .edouard-title {
    max-width: 250px;
  }
  .collab-section .collab-title img {
    max-width: 150px;
  }
  .edouard-section .edouard-slider .slick-slider .slick-prev,
  .edouard-section .edouard-slider .slick-slider .slick-next {
    width: 17px;
    height: 40px;
    background-size: 17px 40px;
  }
  .edouard-section .edouard-slider .slick-slider .slick-prev {
    left: -10px;
  }
  .collab-section .collab-title {
    top: -20px;
  }
  .collab-section {
    padding: 10px 0px 20px;
  }
  .toundery-section .toundery-list ul {
    flex-wrap: wrap;
  }
  .faq-cartoon-img-one img {
    max-width: 85px;
  }
  .footer-content .footer-icon img {
    max-width: 100px;
  }
  .footer-content ul li img {
    width: 20px;
  }
  .footer-content ul li {
    margin: 0 2px;
  }
  .faq-section .faq-cartoon-img-two img {
    max-width: 55px;
  }
  .faq-section .faq-cartoon-img-two {
    bottom: -19px;
  }
  .banner-section .banner-cartoon-img {
    top: 60px;
  }
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.toundery-section {
  z-index: 200;
}
.hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100%);
  transition: all 1s;
}
.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}

/* @media (max-width: 574px) {
  .hidden1:nth-child(2) {
    transition-delay: 600ms;
  }
  .hidden1:nth-child(3) {
    transition-delay: 400ms;
  }
  .hidden1:nth-child(4) {
    transition-delay: 200ms;
  }
} */

.hidden1:nth-child(2) {
  transition-delay: 200ms;
}
.hidden1:nth-child(3) {
  transition-delay: 400ms;
}
.hidden1:nth-child(4) {
  transition-delay: 600ms;
}

@media (prefers-reduced-motion) {
  .hidden {
    transition: none;
  }
}

.accordion-body {
  padding-right: 102px;
  padding-left: 102px;
}

.neonText {
  font-family: 'SF Pro Display';
  color: #fff;
  /* transition: all 1s; add later */
  text-shadow: 0 0 7px #f155b3, 0 0 10px #f155b3, 0 0 21px #f155b3, 0 0 42px #f155b3, 0 0 82px #f155b3, 0 0 92px #f155b3, 0 0 102px #f155b3, 0 0 151px #f155b3;
}
